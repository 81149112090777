
import Vue from "vue"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default Vue.extend({
  layout: "clean",
  async mounted() {
    let payload = {
      params: {
        key: this.$route.query.key as string,
      },
    }
    await EvercamApi.shares.deleteShareRequest(payload)
  },
})
